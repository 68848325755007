// http.js
const getBaseUrl = () => {
    let url;
    // console.log("enviroment: ", process.env.NODE_ENV );
    switch (process.env.NODE_ENV) {
        case 'production':
            // url = 'http://192.168.12.110:8080/api/test/';
            // url = 'http://localhost:8080/api/test/';
            url = 'https://www.pgaodds.co/app/';
            // url = 'http://172.31.62.109:8080/api/test/';
            break;
        default:
            url = 'http://localhost:8080/app/';
            // url = 'http://172.31.62.109/api/test/';
            // url = 'https://3.82.186.43:8080/api/test/';
            // url = 'https://www.pgaodds.co/api/test/';
    }
    return url;
}

export default getBaseUrl;